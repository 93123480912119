import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';

import { Models } from './index';
import { IEmployeeBoxProps } from '../components/employee-box/employee-box';
import { IEmployeeBox } from '../typings/kentico';

export interface IEventItem {
  title: string;
  description: string;
  date: string;
  url: string;
  urlText: string;
  lecturer: IEmployeeBoxProps;
}

export class EventModel extends ContentItem {
  get title(): string {
    return get(this._config, 'elements.title_value.value', '');
  }

  get description(): string {
    return get(this._config, 'elements.description_value.value', '');
  }

  get date(): string {
    return get(this._config, 'elements.date.value', '');
  }

  get url(): string {
    return get(this._config, 'elements.url.value', '');
  }

  get urlText(): string {
    return get(this._config, 'elements.url_text.value', '');
  }

  get lecturer(): IEmployeeBoxProps {
    const lecturerData = get(this._config, 'elements.lecturer.value[0]', {});
    const modeledData = Models.EmployeeBoxModel.create(
      lecturerData as IEmployeeBox
    );

    return modeledData;
  }

  public static create(data: IEvent): IEventItem {
    const { title, description, date, url, urlText, lecturer } = new EventModel(
      data
    );

    return {
      title,
      description,
      date,
      url,
      urlText,
      lecturer
    };
  }
}
