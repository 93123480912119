import { format } from 'date-fns';
import React, { FunctionComponent, useMemo } from 'react';
import { IEventItem } from '../../models/event-model';
import { getLocaleByCode } from '../../utils/locale';
import { ArticleAuthorSmallBox } from '../article-author-small-box/article-author-small-box';
import styles from './event-item.module.scss';

interface IEventItemProps extends IEventItem {
  language: string;
  pastEvent?: boolean;
  displayAuthor?: boolean;
}

export const EventItem: FunctionComponent<IEventItemProps> = ({
  title,
  description,
  date,
  url,
  urlText,
  lecturer,
  language,
  pastEvent,
  displayAuthor
}) => {
  const locale = useMemo(() => getLocaleByCode(language), [language]);
  const dateToDisplay = useMemo(
    () =>
      format(new Date(date), 'dd MMMM, yyyy', {
        locale
      }),
    [date, locale]
  );

  return (
    <div className={`${styles.eventWrapper} ${pastEvent && styles.pastEvent}`}>
      {lecturer && displayAuthor && (
        <div className={styles.author}>
          <ArticleAuthorSmallBox
            theme="dark"
            author={lecturer}
            displayPosition={true}
          />
        </div>
      )}
      <div
        className={`${styles.content} ${!displayAuthor && styles.noPadding}`}
      >
        <div className={styles.date}>{dateToDisplay}</div>
        <h3 className={styles.title}>{title}</h3>
        {url && (
          <a
            className={styles.link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            @{urlText}
          </a>
        )}
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>
    </div>
  );
};
