import { graphql, navigate } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  useState,
  useContext,
  useEffect,
  FunctionComponent,
  useMemo
} from 'react';
import { PageProps } from 'gatsby';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import '../styles/global.scss';
import '../styles/reset.css';
import { pageDataResolver } from '../utils/pageDataResolver';
import { SeoComponent } from '../components/seo-component/seo-component';
import { Navbar } from '../components/navbar/navbar';
import { Footer } from '../components/footer/footer';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import { BlogArticleModel } from '../models/blog-article.model';
import { ArticleHero } from '../components/article-hero/article-hero';
import { EmployeeBoxModel } from '../models/employee-box-model';
import { BlogAuthorSection } from '../components/blog/blog-author-section/blog-author-section';
import styles from '../styles/author.module.scss';
import { NavTabTextItem } from '../components/tabs-menu/nav-tab-text-item/nav-tab-text-item';
import { TabsNav } from '../components/tabs-menu/tabs-nav/tabs-nav';
import { Container } from '../components/container/container.component';
import {
  ArticleListItem,
  IArticleItem
} from '../components/blog/article-list-item/article-list-item';
import { ButtonLoader } from '../components/button-loader/button-loader';
import { authorUrl, blogArticleUrl, filterById } from '../utils/utils';
import { useGlobalTranslations } from '../hooks/use-global-translations';
import { BLOG_URL } from '../const/url';
import { EventModel, IEventItem } from '../models/event-model';
import { EventItem } from '../components/event-item/event-item';
import { ISortedEvents } from './speaking-page';
import { Button } from '../components/button/button';
import { withCookieBar } from '../hoc/withCookieBar';

interface IData {
  pageData: IPage;
  kontentItemEmployee: IEmployeeBox;
  allKontentItemBlogArticle: {
    nodes: IBlogArticle[];
  };
  allKontentItemEvent: {
    nodes: IEvent[];
  };
  speakingUrl: {
    elements: {
      basic_page_settings__url: {
        value: string;
      };
    };
  };
  cookieSection: ICookieData;
}

type AuthorPageProps = PageProps<IData, IPageContext>;

const AuthorPage: FunctionComponent<AuthorPageProps> = props => {
  const { dispatch, state } = useContext(AppStore);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [progressLinePostition, setProgressLinePostition] = useState(0);
  const pageData = useMemo(() => pageDataResolver(props.data.pageData), [
    props.data.pageData
  ]);
  const articles = useMemo(
    () =>
      props.data.allKontentItemBlogArticle.nodes.map(article =>
        BlogArticleModel.create(article)
      ),
    [props.data.allKontentItemBlogArticle.nodes]
  );
  const latestArticle = articles[0];
  const authorData = useMemo(
    () => EmployeeBoxModel.create(props.data.kontentItemEmployee),
    [props.data.kontentItemEmployee]
  );

  const {
    myBlogPosts,
    myPresentations,
    myLatestBlogPosts,
    loadMore
  } = useGlobalTranslations();

  // Tabs menu config
  const articlesToShow = 4;
  const [articlesListLength, setArticlesCount] = useState(articlesToShow);
  const articlesList = articles.slice(0, articlesListLength);

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });
    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          currentUrl: props.pageContext.pageUrl || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    const debouncedResize = debounce(handleResize, 300);

    debouncedResize();
    handleChangePageState();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    state.showActiveDevice,
    state.showPageState.currentUri,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    props.data.cookieSection.elements.text.value,
    props.data.cookieSection.elements.button_cta.value
  ]);

  const changeTabHandler = (event: React.MouseEvent<HTMLElement>): void => {
    const elem = event.target as HTMLDivElement;
    const newProgressLinePostion = elem.offsetLeft;
    const index = elem.dataset.index || 0;

    event.preventDefault();

    if (+index !== activeTabIndex) {
      setActiveTabIndex(+index);
      setProgressLinePostition(newProgressLinePostion);
    }
  };

  const onButtonClick = (): void => {
    setArticlesCount(articlesListLength + articlesToShow);
  };

  const filteredEvents = useMemo(
    (): IEvent[] => filterById(props.data.allKontentItemEvent.nodes),
    [props.data.allKontentItemEvent.nodes]
  );

  const navTabs = [myBlogPosts, filteredEvents.length ? myPresentations : ''];

  const modeledEvents = useMemo(
    () => filteredEvents.map(ev => EventModel.create(ev)),
    [filteredEvents]
  );

  const sortedEvents = useMemo(() => {
    const today = new Date();
    return modeledEvents.reduce(
      (events: ISortedEvents | undefined, ev: IEventItem) => {
        if (!events) {
          return undefined;
        }

        const eventDate = new Date(ev.date);

        if (today > eventDate) {
          events.pastEvents.push(ev);
          return events;
        }

        events.upcomingEvents.push(ev);
        return events;
      },
      {
        upcomingEvents: [],
        pastEvents: []
      }
    );
  }, [modeledEvents]);

  const navigateToArticle = (
    event: React.MouseEvent<HTMLElement>,
    slug: string,
    category: string
  ): void => {
    event.preventDefault();

    const targetElem = event.target as HTMLElement;
    const articleUrl = blogArticleUrl(slug, category);

    if (targetElem.tagName === 'A') {
      return;
    }

    props.pageContext.pageLanguage !== 'en'
      ? navigate(`/${props.pageContext.pageLanguage}${articleUrl}`)
      : navigate(`${articleUrl}`);
  };

  return (
    <>
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      {latestArticle && (
        <div
          style={{ cursor: 'pointer' }}
          onClick={(event): void =>
            navigateToArticle(
              event,
              latestArticle.slug,
              latestArticle.categoryUrl
            )
          }
        >
          <ArticleHero
            linkBackUrl={BLOG_URL}
            backgroundImage={latestArticle.image}
            title="BLOG"
            headlineLight={myLatestBlogPosts}
            headline={latestArticle.title}
            bottomInfo={latestArticle.categoryName}
          />
        </div>
      )}
      <BlogAuthorSection author={authorData} withSocialLinks={true} />
      <Container>
        <div className={styles.authorData}>
          <TabsNav
            progressLinePostition={progressLinePostition}
            className={styles.tabsNav}
          >
            {navTabs.map((navTab, i) => (
              <React.Fragment key={i}>
                {navTab.length ? (
                  <NavTabTextItem
                    isActive={activeTabIndex === i}
                    key={i}
                    index={i}
                    text={navTab}
                    changeTab={changeTabHandler}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </TabsNav>
          <div className={styles.data}>
            {activeTabIndex === 0 ? (
              <div className={styles.blogPosts}>
                {articlesList.map((article: IArticleItem, i: number) => (
                  <ArticleListItem
                    className={styles.listItem}
                    data={article}
                    key={i}
                  />
                ))}
                {articlesListLength < articles.length && (
                  <div className={styles.loader}>
                    <ButtonLoader
                      buttonText={loadMore}
                      onClick={onButtonClick}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.events}>
                {sortedEvents &&
                  sortedEvents.upcomingEvents.map((ev, i) => (
                    <EventItem
                      key={i}
                      language={props.pageContext.pageLanguage}
                      {...ev}
                    />
                  ))}
                {sortedEvents &&
                  sortedEvents.pastEvents.map((ev, i) => (
                    <EventItem
                      pastEvent
                      key={i}
                      language={props.pageContext.pageLanguage}
                      {...ev}
                    />
                  ))}
                <div className={styles.buttonWrapper}>
                  <Button
                    border="rectangular"
                    size="large"
                    theme="black"
                    CTA={true}
                    text="Check Our lectures shedule"
                    transofrmUppercase={true}
                    className={styles.eventsButton}
                    internalLink={
                      props.data.speakingUrl.elements.basic_page_settings__url
                        .value
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
      {pageData.footerData.linkColumns.length && (
        <Footer {...pageData.footerData} />
      )}
    </>
  );
};

export const Head = (props: AuthorPageProps) => {
  const { authorAtSoftwareHut } = useGlobalTranslations();

  const authorData = useMemo(
    () => EmployeeBoxModel.create(props.data.kontentItemEmployee),
    [props.data.kontentItemEmployee]
  );

  return (
    <SeoComponent
      title={`${authorData.firstName} ${authorData.lastName}, ${authorAtSoftwareHut}`}
      description={authorData.biography ? authorData.biography : ''}
      noIndex={'false'}
      ogType="article"
      ogImage={authorData.photoUrl ? authorData.photoUrl : undefined}
      seoCanonicalLink={authorUrl(authorData.slug)}
      currentUrl={props.location.href}
      lang={props.pageContext.pageLanguage || DEAFUALT_LANGUAGE}
    />
  );
};

export default withAppStore(
  withCookieBar(React.memo(AuthorPage as FunctionComponent<{}>))
);

export const query = graphql`
  query($pageLanguage: String, $id: String) {
    pageData: kontentItemPage(
      system: { language: { eq: $pageLanguage }, codename: { eq: "homepage" } }
    ) {
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    speakingUrl: kontentItemPage(
      system: { language: { eq: $pageLanguage }, codename: { eq: "speaking" } }
    ) {
      ...FragmentUrl
    }
    kontentItemEmployee(
      id: { eq: $id }
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentEmployee
    }
    allKontentItemBlogArticle(
      filter: {
        elements: { author: { value: { elemMatch: { id: { eq: $id } } } } }
      }
      sort: { fields: elements___publication_date___value, order: DESC }
    ) {
      nodes {
        ...FragmentBlogArticleListItem
      }
    }
    allKontentItemEvent(
      filter: {
        system: { language: { eq: $pageLanguage } }
        elements: { lecturer: { value: { elemMatch: { id: { eq: $id } } } } }
      }
    ) {
      nodes {
        ...FragmentEvent
      }
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
  }
`;
